/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { Box, Divider } from '@mui/material';
import { DealPostType } from '@types';
import SelectMainImage from '../SelectMainImage';

const MainImageSelector = ({
  ASIN,
  productImages,
  dealSummary,
  isDealSummaryLoading,
  mainImage,
  setMainImage,
  additionalImages,
  setAdditionalImages
}: {
  ASIN: string;
  productImages: string[];
  dealSummary: DealPostType | null;
  isDealSummaryLoading: boolean;
  mainImage: string | null;
  setMainImage: (image: string) => void;
  additionalImages: string[];
  setAdditionalImages: (images: string[]) => void;
}) => {
  if (!ASIN) {
    return null;
  }

  if (isDealSummaryLoading) {
    return (
      <Box>
        <Box>Loading...</Box>
        <Divider />
      </Box>
    );
  }

  const images = dealSummary?.highResImages?.split(',') || [];

  if (images.length === 0) {
    return null;
  }

  // render a dropdown for choosing a single main image from the highResImages
  // and show the image above on change

  return (
    <SelectMainImage
      value={mainImage || ''}
      onChange={(e) => {
        setMainImage(e.target.value);
        if (additionalImages.includes(e.target.value)) {
          setAdditionalImages(
            additionalImages.filter((img) => img !== e.target.value)
          );
        }
      }}
      ASIN={ASIN}
      allImages={[...productImages, dealSummary.image600Url, ...images]}
    />
  );
};

export default MainImageSelector;
